import React from "react";
import styled from "styled-components";
import Layout from "../Layout/Layout";
import Button from "@mui/material/Button";

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  color: #e0e0e0; /* Light grey for good contrast */
`;

const Title = styled.h1`
  font-size: 3rem;
  margin-bottom: 20px;
  color: #ffffff; /* White for high contrast */
`;

const Content = styled.p`
  font-size: 1.5rem;
  width: 60%;
  margin-bottom: 2rem;
  color: #cccccc; /* Slightly lighter grey for readability */
`;

const CustomButton = styled(Button)`
  background-color: #ffffff !important; /* White background */
  color: #000000 !important; /* Black text */
  font-weight: 600 !important;
  border: 2px solid #ffffff !important; /* White border */

  &:hover {
    background-color: #000000 !important; /* Black background on hover */
    color: #ffffff !important; /* White text on hover */
  }
`;

const Contact = () => (
  <Layout>
    <PageContainer>
      <Title>Contact</Title>
      <Content>
        Reach out to our production team for a test, quote or any enquiry.
      </Content>
      <CustomButton variant="outlined" href="mailto:sj@positivechaosvfx.com">
        Mail Production
      </CustomButton>
    </PageContainer>
  </Layout>
);

export default Contact;
